/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
var now = new Date().getTime();

var lg_min = 1066;
var sm_min = 751;
var xs_min = 320;


// global variable for the player
var player;


function removeHeight(target) {
    jQuery('> *', target).each(function () {
        jQuery(this).css('height', 'auto');
    });
    setTimeout(function () {
        sameHeight('.autoHeightChildren');
    }, 1000);
}

function sameHeight(target) {

    if (window.innerWidth < 768) {
        jQuery('> *', target).each(function () {
            jQuery(this).css('height', 'auto');
        });

    }
    else {
        var maxHeight = 0;
        jQuery(target).each(function () {
            maxHeight = 0;
            var targ = jQuery(this);
            jQuery('*', targ).each(function () {


                if (jQuery(this).outerHeight() >= maxHeight) {
                    maxHeight = jQuery(this).outerHeight();
                    //alert(outerHeight);
                }
            });

            //alert(maxHeight);
            jQuery('> *', targ).each(function () {
                jQuery(this).css('height', maxHeight + "px");
            });
        });
    }

}


function youtubeFit() {
    target = jQuery('iframe');

    target.css('width', '100%').css('height', target.parent().css('height'));
}
function onPlayerReady(event) {

    // bind events
    jQuery('.playButton-yt').click(function () {
        jQuery('.playButton-yt').hide().next().hide();
        jQuery('#video').show();
        player.playVideo();
    });


    /*
     var pauseButton = document.getElementById("pause-button");
     pauseButton.addEventListener("click", function() {
     player.pauseVideo();
     });
     */
}

// this function gets called when API is ready to use
function onYouTubePlayerAPIReady() {

    // create the global player from the specific iframe (#video)
    player = new YT.Player('video', {
        events: {
            // call this function when player is ready to use
            'onReady': onPlayerReady,
            'onStateChange': function (event) {
                if (event.data === YT.PlayerState.PLAYING) {
                }
                else if (event.data === YT.PlayerState.PAUSED) {
                    jQuery('.playButton-yt').show().next().show();
                    jQuery('#video').hide();
                    player.pauseVideo();
                }
                else {

                }


            }
        }
    });

}


// Auto move the dots dependant on window size.
function moveOwlNav() {

    dots = jQuery('.owl-controls', '.homepage-caroucel');


    var winSize = window.innerWidth;

    offsetTarget = jQuery('.owl-item.active .image', '.homepage-caroucel');
    target = jQuery('.owl-item.active .text', '.homepage-caroucel');

    // Small screens dont require this additional offset
    if (winSize > sm_min) {

        imgWidth = parseInt(offsetTarget.css('width').replace('px', ''));

    }
    else {
        imgWidth = 0;
    }

    //alert(parseInt(jQuery('.owl-dots', '.homepage-caroucel').css('width')));
    dotsWidth = jQuery('.owl-dots', '.homepage-caroucel').css('width');

    if(dotsWidth !== undefined){
        dotsWidth = dotsWidth.replace('px', '');
    }


    containerWidth = target.css('width');
    if(containerWidth !== undefined){
        containerWidth = containerWidth.replace('px','');
    }



    totalOffset = imgWidth + (containerWidth / 2) - (dotsWidth / 2);


    // Move the element
    dots.css('left', totalOffset + 'px');
}


function manageBackToTop() {


    var $window = jQuery(window);

    // Top of the viewable window
    var docViewTop = $window.scrollTop();
    // Bottom of the viewable window
    var docViewBottom = docViewTop + $window.height();
    // Very bottom of the document
    var bodyHeight = jQuery('body').css('height').replace('px', '');

    if (parseInt(docViewBottom) >= parseInt(bodyHeight) - parseInt($window.height())) {
        jQuery('#backToTop').css('opacity', 1);
    } else {
        jQuery('#backToTop').css('opacity', 0);
    }

}
function buildSmallOwlCarousel(target) {
    var caroucel = jQuery('.carousel.small', target);
    caroucel.owlCarousel({
        loop: false,
        margin: 0,
        autoWidth: false,
        nav: true,
        dots: false,
        responsive: {
            0: {
                items: 1
            },
            751: {
                items: 2
            },
            1266: {
                items: 3
            }
        }
    });

    return caroucel;
}
function tabs() {
    jQuery('.tabbed').click(function () {
        jQuery(this).next().find('.button').toggleClass('setThree setTwo');
        var next = jQuery(this).parent().is('p') ? jQuery(this).parent() : jQuery(this);
        next.next().toggleClass('hidden-xs');
        next.next().toggleClass('mobileBg');
        return false;
    });
}
// Search by direct id, of if search, find next element that is a video
function fullScreenVideo(id, search) {

    if (jQuery('#' + id).length === 0 && typeof(search) === undefined) {
        return;
    }

    var player;
    jQuery('.playButton').live('click', function (event) {

        if (search === true) {
            id = "#" + jQuery(this).parent().next().attr('id');
        }
        player = _V_(id);

        player.requestFullscreen();
        player.play();
    });

    jQuery(window).bind('webkitfullscreenchange mozfullscreenchange fullscreenchange', function (e) {
        var state = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
        var event = state ? 'FullscreenOn' : 'FullscreenOff';
        if (event === "FullscreenOff") {
            player = _V_(id);
            player.pause();
        }
    });
}
function buildProductsAccordian() {
    var caroucel = jQuery('.products');

    if (caroucel.hasClass('owl-loaded')) {
        caroucel.data('owlCarousel').destroy();
        caroucel.removeClass('owl-loaded');
        caroucel.removeClass('owl-carousel');
        caroucel.find('.owl-stage-outer').children().unwrap();
    }
}
function buildProductsCaroucel() {
// Caroucel for products

    var caroucel = jQuery('.products');
    // if it exists, continue
    if (caroucel.hasClass('owl-carousel')) {
        return;
    }

    caroucel.owlCarousel({
        items: 3,
        center: true,
        loop: true,
        nav: true,
        dots: false,
        responsive: {
            480: {
                items: 1
            },
            751: {
                items: 2
            },
            990: {
                items: 3
            }
        }
    });
}
function manageProductsCaroucel() {

    var winSize = window.innerWidth;

    if (winSize > 480) {
        buildProductsCaroucel();
    } else {
        buildProductsAccordian();
    }
}
// calculate width of tabs
function calulateListWidths(target) {

    jQuery(target).each(function () {
        var count = jQuery('li', jQuery(this)).size();
        var width = (100 / count) + "%";
        jQuery('li', jQuery(this)).css('width', width);
    });


}
function calculateMarginTop(maxWidth) {
    if (window.innerWidth > 0) {
    }

    var height = jQuery('header').css('height');

    jQuery('.autoMarginTop').css('margin-top', height);
}

function autoSizeCaroucel() {
    var winSize = window.innerWidth;


    var height = jQuery('.carousel .active .image img').height();
    var targets = ".carousel-inner .image, .carousel-inner .text";

    if (winSize > lg_min) {
        targets += ",#carousel, .smallBlocks";
    } else {
        jQuery('#carousel, .smallBlocks').css('height', 'auto');
    }
    jQuery(targets).css('height', height);

    jQuery('.smallBlocks .block').height(height / 2 + "px");

}
function autoSizeFunctions() {
    var winSize = window.innerWidth;
    var tallest, parent = 0;
    // Auto height elements to be as tall as talles element
    jQuery('.jsAutoHeightParent').each(function () {
        parent = this;
        tallest = 0;

        jQuery('.jsAutoHeight', parent).each(function () {
            jQuery(this).css('height', 'auto');
            if (jQuery(this).css('height').replace('px', '') > tallest) {
                tallest = jQuery(this).css('height').replace('px', '');
            }
        });


        jQuery('.jsAutoHeight', parent).css('height', tallest + "px");
    });

}
function smHeightInit(cls) {
    cls = typeof cls !== 'undefined' ? cls : ".active";
    jQuery('.smHeight.init', cls).each(function () {

        if (window.innerWidth > sm_min) {
            var left = jQuery(this);
            var next = left.next();


            var leftHeight = (left.css('height').replace("px", ""));
            var nextHeight = (next.css('height').replace("px", ""));

            if (leftHeight <= nextHeight) {
                left.css('height', nextHeight + "px");
                next.css('height', "auto");
            } else {
                next.css('height', leftHeight + "px");
                left.css('height', "auto");
            }
        } else {
            jQuery('.smHeight').css('height', 'auto');
        }
    });
}
(function ($) {

    var Sage = {
        // All pages
        'common': {
            init: function () {

                $(window).load(function () {
                    sameHeight('.autoHeightChildren');

                });
                $(window).resize(function () {
                    removeHeight('.autoHeightChildren');
                });


                // If there is a # then scroll to it if -scroll exists in the hash
                if (window.location.hash) {
                    var hash = window.location.hash;
                    // Do tabby work :)
                    if (hash.indexOf("+") !== -1) {
                        var multi = hash.split("+");
                        $('a[href="' + multi[0] + '"]').tab('show');
                        $('a[href="#' + multi[1] + '"]').tab('show');
                    } else {
                        $('a[href="' + hash + '"]').tab('show');
                    }

                    if (hash.indexOf("-scrolled") !== -1) {
                        var pos = hash.lastIndexOf('-');
                        hash = hash.substring(0, pos);
                    }
                    if ($(hash).length !== 0) {
                        $('body,html').animate({
                            'scroll-top': $(hash).offset().top + "px"
                        }, 1000);
                    }
                }

                $('input + label', '.rdCbCont').click(function () {
                    //$(this).prev().trigger('click');

                    //$(this).prev().prop('checked',true);
                    //alert("A");

                });
                $('.qqMenu').click(function () {
                    $('.qqMenu').toggleClass('quoteOpen');
                });

                $('.quoteOpener').click(function(){

                    $('.qqMenu').toggleClass('quoteOpen');

                });
                $('.qqMenuFixed').click(function () {
                    $('body,html').animate({
                        'scroll-top': '0px'
                    }, 1000, function () {
                        $('.qqMenu').addClass('quoteOpen');
                    });
                    return false;
                });

                $('.primary .hoverItem').hover(function () {
                    $('header').toggleClass('biggerIT');
                });

                $('.scrollToPos').click(function () {
                    if ($(this).attr('data-scroll-id')) {

                        // check to see if the element is visible. if not add collapse- to front
                        var target = $($(this).attr('data-scroll-id')).is(':visible') ? $(this).attr('data-scroll-id') : $(this).attr('data-scroll-id-alt');
                        var headerHeight = $('header').css('height').replace('px', '');
                        $('body,html').animate({
                            'scroll-top': parseInt($(target).offset().top) - parseInt(headerHeight) + "px"
                        }, 1000);
                    }

                });
                $('.svgSpan').click(function () {
                    if ($(this).parents('form').length !== 0) {
                        $(this).parents('form').submit();
                    }

                });
                $(window).resize(function () {
                    calculateMarginTop();
                    smHeightInit();
                    //autoSizeCaroucel();
                    //autoSizeFunctions();
                });
                smHeightInit();
                calculateMarginTop();

                $(window).bind('load', function () {
                    //autoSizeCaroucel();
                });
                //autoSizeFunctions();

                $('nav.primary').hover(function () {
                    $('.sub_nav').show();
                }, function () {
                    $('.sub_nav').hide();
                });


                $('li.menu-item-has-children', 'nav.mobile').not('.openToggle').click(function (e) {
                    if ($("a", $(this)).next().hasClass('sub-menu')) {
                        $("a", $(this)).next().toggle();
                        e.preventDefault();
                        return false;
                    }
                });

                $('li.menu-item', 'nav.mobile').click(function (e) {
                    e.preventDefault();
                    if ($('a', $(this)).attr('href') !== "#") {
                        window.location = $('a', $(this)).attr('href');
                    }
                    return false;
                });
                $('li.menu-item-has-children', 'nav.mobile').click(function () {
                    $(this).toggleClass('openToggle');
                });

                // JavaScript to be fired on all pages
                $(window).on('scroll', function (e) {
                    // If the window offset is more then 100
                    if (window.pageYOffset > 50) {   // Only fire the event every 1 seconds
                        if (new Date().getTime() - now > 500) {
                            $('header').addClass('scrolled');
                            $('.switch').hide();
                            $('.switchHidden').show();
                            $('.switchHidden').removeClass('white');
                            $('.quick-quote a').addClass('switchA');

                            // reset the timer
                            now = new Date().getTime();

                            manageBackToTop();
                        }
                        // Touch devices get height of header from last touch so..
                        // this helps reset it
                        setTimeout(function () {
                            calculateMarginTop();
                        }, 500);
                    } else {
                        $('.switch').show();
                        $('.switchHidden').hide();
                        $('header').removeClass('scrolled');
                        $('.switchHidden').addClass('white');
                        $('.quick-quote a').removeClass('switchA');
                    }
                });


                var slideout = new Slideout({
                    'panel': document.getElementById('myPanel'),
                    'menu': document.getElementById('menu'),
                    'padding': 256,
                    'side': 'right',
                    'tolerance': 70
                });
                // Toggle button
                $('.mobile-menu').on('click', function () {
                    slideout.toggle();
                });

                $('.wrap').on('click', function () {
                    slideout.close();
                });

                $('.submitSvg').on('click', function () {

                });
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {

                var caroucel = jQuery('.homepage-caroucel');
                // if it exists, continue

                caroucel.owlCarousel({
                    items: 1,
                    singleItem: true,
                    animateOut: 'fadeOut',
                    center: true,
                    autoplay: true,
                    autoplayTimeout: 5000,
                    autoplayHoverPause: true,
                    loop: true,
                    nav: false,
                    transitionStyle: "fade",
                    dots: true,
                    onInitialized: function () {
                        moveOwlNav();
                    },
                    onResized: function () {
                        moveOwlNav();
                    }
                });


                fullScreenVideo('', true);


                // JavaScript to be fired on the home page
                manageProductsCaroucel();

                $(window).resize(function () {
                    manageProductsCaroucel();
                });


                var $result = $('.owl-dots div').length;
                var $widthDot = 100 / $result;

                $('.owl-dots div').css('width',$widthDot + '%');
                

                $('.products .item .title').click(function () {
                    jQuery(this).parent().toggleClass('hid');
                });
            },

            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        'guardian_extension': {
            init: function () {

              var caroucel2 = jQuery('.extensionTwo-caroucel');
              // if it exists, continue

              caroucel2.owlCarousel({
                  items: 1,
                  autoplay:true,
                  center: false,
                  loop: true,
                  nav: true,
                  dots:false,
                  responsive: {
                      0: {
                          items: 1
                      },
                      751: {
                          items: 1
                      }
                  }
              });


                // Load video
                //fullScreenVideo('guardian-video');
                $('#explore-heading a').click(function (e) {
                    e.preventDefault();
                    $(this).tab('show');
                });

                fakewaffle.responsiveTabs(['xs']);

                $('#explore a').click(function (e) {
                    e.preventDefault();
                    $(this).tab('show');
                    //alert("a");
                    sameHeight('.autoHeightChildren');
                });

                // Calculate widths of tabbed headdings
                calulateListWidths('#tabbedSet ul.nav');

                // Tabs when clicked need to refresh owl carousel
                $('.explore li').click(function () {
                    var caroucel = $('.carousel.small', $("a", $(this)).attr('href'));

                    caroucel.trigger('destroy.owl.carousel').removeClass('owl-carousel owl-loaded');
                    caroucel.find('.owl-stage-outer').children().unwrap();
                    caroucel = buildSmallOwlCarousel($("a", $(this)).attr('href'));
                    caroucel.removeClass('owl-hidden');

                });
                // Bit more differennt... destroy on click

                var href = ("#collapse-external_finish_options")

                $('#explore li').on('click', function () {
                    // if it exists, continue
                    var caroucel = jQuery('.extension-caroucel');
                    caroucel.owlCarousel({
                        items: 1,
                        autoplay: true,
                        autoplayTimeout: 5000,
                        autoplayHoverPause: true,
                        loop: true,
                        dots: false,
                        nav:true,
                    });

                });

                $('.accordion-toggle').on('click', function() {
              setTimeout(function(){
                var caroucel = jQuery('.extension-caroucel');
                caroucel.owlCarousel({
                    items: 1,
                    autoplay: true,
                    autoplayTimeout: 5000,
                    autoplayHoverPause: true,
                    loop: true,
                    dots: false,
                    nav:true,
                });
              },100);



                });


                //Rebuild on show
                // $('.panel-group').on('shown.bs.collapse', function (e) {
                //
                //     var caroucel = $('.carousel.small', $('a[aria-expanded="true"]').attr('href'));
                //     caroucel = buildSmallOwlCarousel($('a[aria-expanded="true"]').attr('href'));
                //     caroucel.removeClass('owl-hidden');
                //     caroucel.css('display', 'block');
                //
                // });

                $('a', '.pins').click(function () {
                    $('.nav-tabs a[href="' + $(this).attr('href') + '"]').tab('show');
                    return false;
                });
                buildSmallOwlCarousel('body');

            }
        },

        'home_col_2_2': {
            init: function () {


                var caroucel = jQuery('.homepage-caroucel');
                // if it exists, continue

                caroucel.owlCarousel({
                    items: 1,
                    singleItem: true,
                    animateOut: 'fadeOut',
                    center: true,
                    autoplay: true,
                    autoplayTimeout: 5000,
                    autoplayHoverPause: true,
                    loop: true,
                    nav: false,
                    transitionStyle: "fade",
                    dots: true,
                    onInitialized: function () {
                        moveOwlNav();
                    },
                    onResized: function () {
                        moveOwlNav();
                    }
                });

                fullScreenVideo('', true);

                // JavaScript to be fired on the home page
                manageProductsCaroucel();

                $(window).resize(function () {
                    manageProductsCaroucel();
                });

                $('.products .item .title').click(function () {
                    jQuery(this).parent().toggleClass('hid');
                });


            },

            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },

        'home_col_2_tab': {
            init: function () {


                var caroucel = jQuery('.homepage-caroucel');
                // if it exists, continue

                caroucel.owlCarousel({
                    items: 1,
                    singleItem: true,
                    animateOut: 'fadeOut',
                    center: true,
                    autoplay: true,
                    autoplayTimeout: 5000,
                    autoplayHoverPause: true,
                    loop: true,
                    nav: false,
                    transitionStyle: "fade",
                    dots: true,
                    onInitialized: function () {
                        moveOwlNav();
                    },
                    onResized: function () {
                        moveOwlNav();
                    }
                });


                fullScreenVideo('', true);


                // JavaScript to be fired on the home page
               // manageProductsCaroucel();

                //$(window).resize(function () {
                //    manageProductsCaroucel();
                //});

                $('.products .item .title').click(function () {
                    jQuery(this).parent().toggleClass('hid');
                });


            },

            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },

        'roof_finishes': {
            init: function () {
                // Load video
                //fullScreenVideo('guardian-video');
                $('#explore-heading a').click(function (e) {
                    e.preventDefault();
                    $(this).tab('show');
                });

                fakewaffle.responsiveTabs(['xs']);

                $('.explore a,#tabContent a').click(function (e) {
                    e.preventDefault();
                    $(this).tab('show');
                    //alert("a");
                    sameHeight('.autoHeightChildren');
                });

                // Calculate widths of tabbed headdings
                calulateListWidths('#tabbedSet ul.nav');

                // Tabs when clicked need to refresh owl carousel
                $('.explore li,#tabContent a').click(function () {
                    var caroucel = $('.carousel.small', $("a", $(this)).attr('href'));

                    caroucel.trigger('destroy.owl.carousel').removeClass('owl-carousel owl-loaded');
                    caroucel.find('.owl-stage-outer').children().unwrap();
                    caroucel = buildSmallOwlCarousel($("a", $(this)).attr('href'));
                    caroucel.removeClass('owl-hidden');

                });
                // Bit more differennt... destroy on clicl
                $('.panel-heading').on('click', function () {

                    var caroucel = $('.carousel.small', $("a", $(this)).attr('href'));


                    caroucel.css('height', '150px').css('overflow', 'hidden');
                    caroucel.trigger('destroy.owl.carousel').removeClass('owl-carousel owl-loaded');
                    caroucel.find('.owl-stage-outer').children().unwrap();
                });
                // Rebuild on show
                $('.panel-group').on('shown.bs.collapse', function (e) {

                    var caroucel = $('.carousel.small', $('a[aria-expanded="true"]').attr('href'));
                    caroucel = buildSmallOwlCarousel($('a[aria-expanded="true"]').attr('href'));
                    caroucel.removeClass('owl-hidden');
                    caroucel.css('display', 'block');

                });

                $('a', '.pins').click(function () {
                    $('.nav-tabs a[href="' + $(this).attr('href') + '"]').tab('show');
                    return false;
                });
                buildSmallOwlCarousel('body');

            }
        },
        'the_benefits': {
            init: function () {
                // Load video
                fullScreenVideo('', true);

                fakewaffle.responsiveTabs(['xs']);
                $('#explore a').click(function (e) {
                    e.preventDefault();
                    $(this).tab('show');
                    sameHeight('.autoHeightChildren');
                });

                // Calculate widths of tabbed headdings
                calulateListWidths('#tabbedSet');
            }
        },
        'efficiency': {
            init: function () {
                tabs();
            }
        },
        'roof_styles': {
            init: function () {
                var caroucel = jQuery('.owl-carousel-styles');

                caroucel.owlCarousel({
                    loop: true,
                    margin: 0,
                    autoWidth: false,
                    nav: true,
                    dots: false,
                    responsive: {
                        0: {
                            items: 1
                        },
                        751: {
                            items: 3
                        },
                        1066: {
                            nav: false,
                            items: 6
                        }
                    }
                });
                caroucel.on('changed.owl.carousel', function (event) {

                    var selected = $('.owl-item:eq(' + event.item.index + ') .item', event.target);
                    $('.item', event.target).removeClass('active-selected');
                    selected.addClass('active-selected');
                    $('.tab-pane', '#explore-styles').removeClass('active').hide();

                    $("#" + selected.attr('data-target'), '#explore-styles').show();
                    $("#" + selected.attr('data-target'), '#explore-styles').addClass('active');

                });

                $('.item', caroucel).on('click', function () {

                    // Remove all active classes and add to only one
                    $('.item', '#explore-styles').removeClass('active-selected');
                    $(this).addClass('active-selected');

                    // Remove all active classes and add to only one and hide all too
                    $('.tab-pane', '#explore-styles').removeClass('active').hide();

                    $("#" + ($(this)).attr('data-target'), '#explore-styles').show();
                    $("#" + ($(this)).attr('data-target'), '#explore-styles').addClass('active');
                    smHeightInit();
                    smHeightInit();

                });
            }
        },
        'post_type_archive_gallery': {
            init: function () {
                var caroucel = jQuery('.owl-carousel-gallery');
                caroucel.owlCarousel({
                    loop: false,
                    margin: 0,
                    items: 1,
                    autoWidth: false,
                    nav: true,
                    dots: false
                });
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        },
        'contact_us': {
            init: function () {
                resizeMap();
                fullScreenVideo('', true);
                youtubeFit();
                $(window).resize(function () {
                    resizeMap();
                    smHeightInit("", true);
                    youtubeFit();
                });
            }
        },
        'page_template_content_products': {
            init: function () {
                var caroucel = jQuery('.products-caroucel');
                // if it exists, continue

                caroucel.owlCarousel({
                    items: 1,
                    center: false,
                    loop: true,
                    nav: true,
                    responsive: {
                        0: {
                            items: 1
                        },
                        751: {
                            items: 2
                        }
                    }
                });

                fakewaffle.responsiveTabs(['xs']);
                $('#explore a').click(function (e) {
                    e.preventDefault();
                    $(this).tab('show');
                    smHeightInit();

                });

                $(window).resize(function () {
                    smHeightInit('.blocks');
                    youtubeFit();
                });
                smHeightInit('.blocks');
                youtubeFit();
                fullScreenVideo('', true);
                // Calculate widths of tabbed headdings
                calulateListWidths('#tabbedSet ul.nav');

            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);


})(jQuery); // Fully reference jQuery after this point.
